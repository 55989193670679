import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Patient from "./Patient";
import Encounter from "./Encounter";
import { fetchFhirData } from "../services/FhirApiService";


const Records = () => {
  const { t } = useTranslation();
  const userPermission = true;

  const [fhirData, setFhirData] = useState([]);
  const [selectedPatientId, setSelectedPatientId] = useState(null);
  const [selectedPatientName, setSelectedPatientName] = useState("");
  const [showEncounters, setShowEncounters] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const handleSeeDetailsClick = (patientId, nameString) => {
    setSelectedPatientId(patientId);
    setSelectedPatientName(nameString);
    setShowEncounters(true);
  };

  let title = t("records.patient");
  let description = t("records.patient-description");
  const noRecords = t("records.none");

  if (selectedPatientId !== null) {
    title = selectedPatientName;
    description = t("records.encounter");
  } 


  useEffect(() => {
    const fetchData = async () => {
      try {
        let path = null;

        if (selectedPatientId) {
          path = `/Encounter?patient=${selectedPatientId}`;
        } else {
          path = "/Patient";
        }

        const response = await fetchFhirData(path);
        setFhirData(response);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching FHIR data:", error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, [selectedPatientId]);

  if (isLoading) {
    return <div></div>;
  }

  if (fhirData && fhirData.entry && showEncounters) {
    return (
      <div className="records-content">
        <h3>{title}</h3>
        <p>{description}</p>
        <div className="records">
          {fhirData.entry.map((data) => {
            const encounterId = data?.resource?.id;
            return <Encounter key={encounterId} data={data} userPermission={userPermission} />;
          })}
        </div>
      </div>
    );
  }

  if (fhirData && fhirData.entry && selectedPatientId === null) {
    return (
      <div className="records-content">
        <h3>{title}</h3>
        <p>{description}</p>
        <div className="records">
          {fhirData.entry.map((data) => {
            const patientId = data?.resource?.id;
            return (
              <Patient
                key={patientId}
                data={data}
                handleSeeDetailsClick={handleSeeDetailsClick}
                patientId={patientId}
              />
            );
          })}
        </div>
      </div>
    );
  }

  return (
    <div className="records-content">
      <div className="no-records">
        <p>{noRecords}</p>
      </div>
    </div>
  );
};

export default Records;

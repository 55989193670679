import { Navigate, useLocation } from "react-router-dom"

import { userSessionService } from "../services/UserSessionService"


export const LoginRedirect = () => {

  let location = useLocation()

  const { isLoading } = userSessionService.useTokenQuery();

  if (isLoading) {
    return <div>Loading...</div>
  }

  return <Navigate to="/record-viewer"  state={{ from: location }} replace />
}

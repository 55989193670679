// import records from "../assets/records.png";
import { useState } from "react";
// import { useTranslation } from "react-i18next";
import EncounterDetail from "./EncounterDetail";
import { fetchFhirData } from "../services/FhirApiService";


function Encounter({ data, userPermission }) {
  // Update: Receive data as an object
  // const { t } = useTranslation();
  const [showEncounter, setShowEncounter] = useState(false);
  const [observationData, setObservationData] = useState([]);

  const facility = data?.resource?.location?.[0]?.location?.display;
  const careTeamMember = data?.resource?.participant?.[0]?.individual?.display;
  const dateLong = data?.resource?.period?.end;
  const encounterId = data?.resource?.id;

  const timeRegex = /^(.*)(?=T)/;
  const date = dateLong?.match(timeRegex)[1];

  const handleClick = async () => {
    setShowEncounter(!showEncounter);
    try {
      let path = `/Observation?encounter=${encounterId}`;

      const response = await fetchFhirData(path);
      setObservationData(response);
    } catch (error) {
      console.error("Error fetching observation data:", error);
    }
  };

  const buttonText = showEncounter ? "Hide Details" : "Show Details";

  const encounterClass = showEncounter ? "encounter-open" : "encounter-closed";

  return (
    <div className={`record-wrapper ${encounterClass}`}>
      <div className="record-container">
        {userPermission && <button onClick={handleClick}>{buttonText}</button>}
        <div className="record-container-content">
          <h4>{facility ? facility : careTeamMember}</h4>
          <p>{date}</p>
        </div>
      </div>
      {userPermission && showEncounter && (
        <EncounterDetail data={data?.resource} observationData={observationData} />
      )}
    </div>
  );
}

export default Encounter;

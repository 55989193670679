import Records from "../components/Records";
import { useTranslation } from "react-i18next";

import icon from "../assets/icon-log-out.png"
import { useNavigate } from "react-router-dom";
import { userSessionService } from "../services/UserSessionService";
import { useQueryClient } from "react-query";


function RecordsViewer() {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const queryClient = useQueryClient();
  const {mutate: logout } = userSessionService.useLogoutQuery(queryClient);

  const handleLogout = () => {
    logout()
    navigate("/login")
  };

  return (
    <div className="records-viewer-page">
      <div className="welcome-message">
        <h3>{t("records-viewer.title")}</h3>
        <p>{t("records-viewer.description")}</p>
        <button onClick={handleLogout}>
          <p>Log out</p>
          <img src={icon} alt="logout icon" />
        </button>
      </div>
      <Records />
    </div>
  );
}

export default RecordsViewer;

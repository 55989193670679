import { httpClient } from "../config/AxiosConfig"
import { appConfig } from "../config/AppConfig"
import { QueryClient, useMutation, useQuery } from "react-query"


async function logout(): Promise<void> {
  await httpClient({
    url: `${appConfig.baseUrl}/logout`,
    method: "POST",
    withCredentials: true,
  })
}

function useLogoutQuery(queryClient: QueryClient) {
  return useMutation(() => logout(), {
    mutationKey: ["logout"],
    onSuccess() {
      clearToken()
      queryClient.clear()
    }
  })
}


async function fetchToken(): Promise<string> {
  let res = await httpClient({
    url: `${appConfig.baseUrl}/token`,
    method: "GET",
    withCredentials: true,
  })
  return res.data.token
}

function setToken(token: string): string {
  sessionStorage.setItem("fhir-viewer-token", token)
  return token
}

function useTokenQuery() {
  return useQuery({
    queryKey: ["token"],
    queryFn: fetchToken,
    onSuccess: (data) => setToken(data),
    staleTime: 60000, 
  })
}


function clearToken() {
  sessionStorage.removeItem("fhir-viewer-token")
}


export const userSessionService = {
  setToken,
  clearToken,
  useTokenQuery,
  useLogoutQuery,
}

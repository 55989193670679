// import { useEffect, useState } from "react";
// import records from "../assets/records.png";
// import { useTranslation } from "react-i18next";

function Patient({ data, handleSeeDetailsClick, patientId }) {
  // const { t } = useTranslation();
  const name = data?.resource?.name?.[0];
  const nameString = `${name?.given?.[0]} ${name?.family}`;
  const birthdate = data?.resource?.birthDate;

  const handleClick = () => {
    handleSeeDetailsClick(patientId, nameString);
  };

  console.log(data.resource.name[0]);

  return (
    <div className="record-container">
      <button onClick={handleClick}>See Details</button>
      <div className="record-container-content">
        <h4>{nameString}</h4>
        <p>{birthdate}</p>
      </div>
    </div>
  );
}

export default Patient;

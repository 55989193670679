import { useNavigate } from "react-router-dom";
import logo from "../assets/logo.png";

export const MockEmail = () => {

  const navigate = useNavigate();
  
  const handleClick = () => {
    navigate("/login")
  }

  return (
    <div className="email-box email-box-position">

      <div className="email-header">
        <img className="broward-logo" 
          src={logo} 
          alt="Broward County logo" />
      </div>

      <div className="email-content-box">
        <div className="email-message-content">
          <h3>Hello Care Team Member,</h3>
          <p>This email is to inform you that Devon White has provided consent for you to be notified and/or share data about a patient using the Consent Manager.</p>
          <button className="email-button" onClick={handleClick}>
            Go View Records
          </button>
        </div>
        <div className="email-footer">
          <p>
            This is an automated message. Do not respond to this email. For assistance, refer to the <a href="/faq">Frequently Asked Questions</a>
          </p>
          <p>
            Go <a href="/unsubscribe">here</a> to unsubscribe.
          </p>
        </div>
      </div>

    </div>
  )
}
import axios from "axios";
import { appConfig } from "../config/AppConfig";
import { useQuery } from "react-query";

export async function fetchFhirData(path: string) {
  const res = await axios({
    url: `${appConfig.fhirDataUrl}${path}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("fhir-viewer-token")}`,
    }
  })

  return res.data
}


function useFhirQuery(path: string) {
  return useQuery({
    queryKey: ["fhirApiQuery"],
    queryFn: () => fetchFhirData(path),
    staleTime: 60000, 
  })
}

export const fhirApiService = {
  useFhirQuery,
}

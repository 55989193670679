
const isProduction: boolean = process.env.NODE_ENV === 'production'

export const developmentConfig = {
  baseUrl: 'http://localhost:8070/fhir-viewer',
  fhirDataUrl: 'http://localhost:8089/fhir'
}

export const productionConfig = {
  baseUrl: 'https://viewer-backend.demo-955.dev.identos.ca/fhir-viewer',
  fhirDataUrl: 'https://rsa-fhir.demo-955.dev.identos.ca/fhir'
}

export const appConfig = isProduction
  ? productionConfig
  : developmentConfig
  
import { Navigate, useLocation } from 'react-router-dom';


export function RequireAuthentication(
  { children }: { children: JSX.Element }
) {
  let location = useLocation()

  if (sessionStorage.getItem("fhir-viewer-token") === null) {
    return <Navigate to="/login" state={{ from: location }} replace />
  }

  return children;
}

import { I18nextProvider } from "react-i18next";
import "./App.css";
import i18n from "./i18n.js";
import { Router } from "./router/Router";
import { QueryClient, QueryClientProvider } from "react-query";


const queryClient = new QueryClient()

const logClass = sessionStorage.getItem("fhir-viewer-token") ? "logged-in" : "logged-out";

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <I18nextProvider i18n={i18n}>
        <div className={`App ${logClass}`}>
          <Router />
        </div>
      </I18nextProvider>
    </QueryClientProvider>
  );
}

export default App;

import logo from "../assets/logo.png";

import { useTranslation } from "react-i18next";

const Header = () => {
  const { t } = useTranslation();

  return (
    <header className="App-header">
      <img className="broward-logo" src={logo} alt="Broward County logo"/>
      <h3>{t("header.title")}</h3>
    </header>
  );
};

export default Header;

import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import enTranslation from "./locales/en.json";
import frTranslation from "./locales/fr.json";

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: enTranslation },
    fr: { translation: frTranslation },
  },
  fallbackLng: "en",
  debug: false, // Set to true for development to see debug logs
  interpolation: {
    escapeValue: false, // React takes care of escaping
  },
});

export default i18n;

import records from "../assets/records.png";

import { useTranslation } from "react-i18next";
import { appConfig } from "../config/AppConfig";


function LoginPage() {
  const { t } = useTranslation();

  const handleLogin = () => {
    window.location.assign(`${appConfig.baseUrl}/login`)
  };

  return (
    <div className="login-page">
      <div className="login-content">
        <h3>{t("login.header")}</h3>
        <p>{t("login.description")}</p>
        <button onClick={handleLogin}>{t("login.button")}</button>
      </div>
      <img src={records} alt="alt-text"/>
    </div>
  );
}

export default LoginPage;

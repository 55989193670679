import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import RecordsViewer from '../pages/RecordsViewer';
import { RequireAuthentication } from './RequireAuthentication';
import LoginPage from '../pages/LoginPage';
import { LoginRedirect } from '../pages/LoginRedirect';
import { MockEmail } from '../pages/MockEmail';
import { Layout } from '../layouts/Layout';


export const Router = () => {
  let location = useLocation()

  return (
    <Routes>

      <Route 
        path="/mock-email"
        element={<MockEmail />}
      />

      <Route element={<Layout />}>
        <Route
          path="/login"
          element={<LoginPage />}
        />

        <Route
          path="/redirect"
          element={<LoginRedirect />}
        />

        <Route
          path="/record-viewer"
          element={
            <RequireAuthentication>
              <RecordsViewer />
            </RequireAuthentication>
          }
        />

        <Route
          path="/"
          element={<Navigate to="/login" state={{ from: location }} replace />}
        />
      </Route>
      
    </Routes>
  )
}
